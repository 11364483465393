import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Box, Icon, LinkBox, Link, Section, Em } from "@quarkly/widgets";
import { MdPhone } from "react-icons/md";
const defaultProps = {
	"padding": "90px 0 50px 0",
	"background": "--color-darkL1",
	"quarkly-title": "Footer-13",
	"sm-padding": "50px 0 50px 0"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"padding": "50px 30px 50px 30px",
			"display": "flex",
			"justify-content": "space-around",
			"align-items": "center",
			"margin": "0px 0px 80px 0px",
			"md-flex-direction": "column",
			"md-align-items": "stretch",
			"md-margin": "0px 0px 50px 0px",
			"md-padding": "30px 30px 30px 30px",
			"sm-padding": "20px 15px 20px 15px",
			"background": "linear-gradient(290deg,rgba(0, 0, 0, 0.85) 19%,rgba(0,0,0,0) 55.7%,rgba(0, 0, 0, 0.85) 81.9%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6698f9e3995d120018f20fe0/images/360_F_726207644_xbv1wEfKuzido4LAdxGm0JW06JdFSn4L.jpg?v=2024-07-18T11:35:43.655Z) 50% 60%/cover repeat scroll padding-box"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"width": "60%",
			"md-margin": "0px 0px 30px 0px",
			"md-width": "100%",
			"sm-margin": "0px 0px 18px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 400 42px/1.2 --fontFamily-sansHelvetica",
			"color": "--light",
			"md-margin": "0px 0px 15px 0px",
			"sm-margin": "0px 0px 12px 0px",
			"children": <Em>
				Насолоджуйтеся аутентичними смаками Італії разом із нами!
			</Em>
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--light",
			"font": "normal 300 20px/1.5 --fontFamily-sans",
			"children": <>
				Запрошуємо вас завітати до піцерії Піцерія Дивовижна Італія та випробувати наше смачне меню на собі.{" "}
			</>
		}
	},
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"flex-direction": "row",
			"padding": "15px 25px 15px 25px",
			"border-width": "2px",
			"border-style": "solid",
			"border-color": "--color-light",
			"background": "rgba(247, 251, 255, 0.12)",
			"sm-padding": "10px 15px 10px 15px",
			"href": "0674102907"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "md",
			"icon": MdPhone,
			"size": "24px",
			"color": "--light",
			"margin": "0px 8px 0px 0px"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 500 20px/1.5 --fontFamily-sans",
			"color": "--light",
			"children": "0674102907"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"grid-template-columns": "repeat(3, 1fr)",
			"grid-gap": "16px 24px",
			"margin": "0px 0px 80px 0px",
			"md-grid-template-columns": "1fr",
			"md-grid-gap": "36px 0",
			"md-margin": "0px 0px 50px 0px"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 25px 0px",
			"font": "normal 500 24px/1.2 --fontFamily-sansHelvetica",
			"text-align": "center",
			"color": "#ba653f",
			"md-margin": "0px 0px 20px 0px",
			"children": "Адреса"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"color": "#d7c7c5",
			"font": "normal 300 18px/1.5 --fontFamily-sans",
			"text-align": "center",
			"border-color": "#b8acac",
			"md-margin": "0px 0px 15px 0px",
			"children": "Kyivska St, 77, Zhytomyr, Zhytomyr Oblast, 10001"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 25px 0px",
			"font": "normal 500 24px/1.2 --fontFamily-sansHelvetica",
			"text-align": "center",
			"color": "#ba653f",
			"md-margin": "0px 0px 20px 0px",
			"children": "Бронювання"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"color": "#d7c7c5",
			"text-decoration-line": "initial",
			"font": "normal 300 18px/1.5 --fontFamily-sans",
			"display": "flex",
			"text-align": "center",
			"margin": "0px 0px 15px 0px",
			"hover-color": "#f5eceb",
			"href": "0674102907",
			"children": "0674102907"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 25px 0px",
			"font": "normal 500 24px/1.2 --fontFamily-sansHelvetica",
			"text-align": "center",
			"color": "#ba653f",
			"md-margin": "0px 0px 20px 0px",
			"children": "Години роботи"
		}
	},
	"text7": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"color": "#d7c7c5",
			"font": "normal 300 18px/1.5 --fontFamily-sans",
			"text-align": "center",
			"border-color": "#b8acac",
			"children": "Понеділок - п'ятниця (10:00 - 18:00)"
		}
	},
	"text8": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 300 18px/1.5 --fontFamily-sans",
			"color": "#d7c7c5",
			"md-margin": "0px 0px 25px 0px",
			"sm-text-align": "center",
			"text-align": "center",
			"children": "© 2024 Піцерія Дивовижна Італія. All rights reserved."
		}
	}
};

const Footer23 = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Text {...override("text")} />
				<Text {...override("text1")} />
			</Box>
			<LinkBox {...override("linkBox")}>
				<Icon {...override("icon")} />
				<Text {...override("text2")} />
			</LinkBox>
		</Box>
		<Box {...override("box2")}>
			<Box {...override("box3")}>
				<Text {...override("text3")} />
				<Text {...override("text4")} />
			</Box>
			<Box {...override("box4")}>
				<Text {...override("text5")} />
				<Link {...override("link")} />
			</Box>
			<Box {...override("box5")}>
				<Text {...override("text6")} />
				<Text {...override("text7")} />
			</Box>
		</Box>
		<Text {...override("text8")} />
		{children}
	</Section>;
};

Object.assign(Footer23, { ...Section,
	defaultProps,
	overrides
});
export default Footer23;